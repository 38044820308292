import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Hero from '../components/Hero'
import CompanyUnlistedForm from "../components/forms/CompanyUnlistedForm"
import SignUpSection from "../components/SignUpSection"


export default function contact() {

  return(
    <>
      <Seo title = "unlisted company"/>

      <Hero 
        headline = "unlisted company"
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/about-hero.jpg" 
          alt="" 
          loading="eager"
        />
      </Hero>

      <div className="page-content">
        <div className="container">
          <div className="row">

            <div className="col-md-3">
             
            </div>

            <div className="col-md-9">
              
              <CompanyUnlistedForm />

            </div>

          </div>
        </div>
      </div>

      <SignUpSection />
    </>
  )
}